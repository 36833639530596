<template>
  <section>
    <iframe src="https://www.jq22.com/demo/jquery-cloud-141217202931/"></iframe>
    <div class="page faj">
      <div v-if="optStatus" class="module" :class="isApp ? 'module_app' : ''">
        <div class="module_banner faj">
          微世纪综合采购代理服务平台
          <div class="module_top"></div>
          <div class="module_bottom"></div>
        </div>
        <div class="module_data">
          <van-form @submit="login">
            <van-field
              v-model="loginForm.username"
              name="账号"
              label="账号"
              placeholder="请输入手机号"
            />
            <van-field
              v-model="loginForm.password"
              type="password"
              name="密码"
              label="密码"
              placeholder="密码"
            />
            <div style="margin: 16px">
              <van-button round block type="info" native-type="submit"
                >登录</van-button
              >
            </div>
          </van-form>
          <div style="margin: 16px">
            <van-button
              round
              block
              type="info"
              native-type="submit"
              @click="optClick"
              >注册</van-button
            >
          </div>
        </div>
        <div class="notice" v-html="notice"></div>
      </div>
      <div v-if="!optStatus" class="module" :class="isApp ? 'module_app' : ''">
        <div class="module_banner faj">
          微世纪综合采购代理服务平台
          <div class="module_top"></div>
          <div class="module_bottom"></div>
        </div>
        <div class="module_data">
          <van-form @submit="register">
            <van-field
              v-model="registerForm.username"
              name="账号"
              label="账号"
              placeholder="请输入手机号"
            />
            <van-field
              v-model="registerForm.password"
              type="password"
              name="密码"
              label="密码"
              placeholder="密码"
            />
            <van-field
              v-model="registerForm.realname"
              type="realname"
              name="姓名"
              label="姓名"
              placeholder="姓名"
            />
            <van-field
              v-model="registerForm.job"
              type="job"
              name="工作单位"
              label="工作单位"
              placeholder="工作单位"
            />
            <van-field
              readonly
              clickable
              name="area"
              :value="registerForm.area"
              label="地区选择"
              placeholder="点击选择省市区"
              @click="showArea = true"
            />
            <van-popup v-model="showArea" position="bottom">
              <van-area
                :area-list="areaList"
                @confirm="getarea"
                @cancel="showArea = false"
              />
            </van-popup>
            <van-field
              v-model="registerForm.address"
              type="address"
              name="收货地址"
              label="收货地址"
              placeholder="收货地址"
            />
            <van-field
              v-model="registerForm.code"
              name="发票抬头"
              label="发票抬头"
              placeholder="发票抬头"
            />
            <van-field
              v-model="registerForm.num"
              name="税号"
              label="税号"
              placeholder="税号"
            />
            <van-field
              v-model="registerForm.receiver_email"
              name="接收发票邮箱"
              label="接收发票邮箱"
              placeholder="接收发票邮箱"
            />
            <div style="margin: 16px">
              <van-button round block type="info" native-type="submit"
                >注册</van-button
              >
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import { login, register, getNotice } from "@/assets/api/api";
import qs from "qs";
import { Toast } from "vant";
Vue.use(Toast);
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      optStatus: true,
      loginForm: {
        username: "",
        password: "",
      },
      registerForm: {
        username: "",
        password: "",
        realname: "",
        job: "",
        area: "",
        address: "",
        code: "",
        num: "",
        receiver_email: "",
      },
      notice: "",
      showArea: false,
      areaList: areaList,
      isApp: false,
    };
  },
  computed: {},
  methods: {
    getarea(values) {
      this.registerForm.area = values
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    optClick() {
      if (this.optStatus) {
        this.optStatus = false;
      } else {
        this.optStatus = true;
      }
    },
    login() {
      if (this.loginForm.username == "") {
        Toast("请输入手机号");
      } else if (this.loginForm.password == "") {
        Toast("请输入密码");
      } else {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
        });
        login(qs.stringify(this.loginForm)).then((res) => {
          Toast.clear();
          if (res.data.code == 0) {
            sessionStorage.setItem("token", res.data.data.token);
            this.tohome();
          } else {
            sessionStorage.removeItem("token");
            Toast(res.data.msg);
          }
        });
      }
    },
    getNotice() {
      getNotice().then((res) => {
        res.data.content = res.data.content.replace(
          /<img/gi,
          '<img style="height:200px;display:block;margin:0 auto"'
        );
        this.notice = res.data.content;
      });
    },
    register() {
      if (this.registerForm.username == "") {
        Toast("请输入账号");
      } else if (this.registerForm.password == "") {
        Toast("请输入密码");
      } else if (this.registerForm.realname == "") {
        Toast("请输入真实姓名");
      } else if (this.registerForm.job == "") {
        Toast("请输入工作单位");
      } else if (this.registerForm.area == "") {
        Toast("请选择地区");
      } else if (this.registerForm.address == "") {
        Toast("请输入收货地址");
      } else if (this.registerForm.code == "") {
        Toast("请输入发票抬头");
      } else if (this.registerForm.num == "") {
        Toast("请输入税号");
      } else if (this.registerForm.receiver_email == "") {
        Toast("请输入接收发票邮箱");
      } else {
        Toast.loading({
          message: "加载中...",
          forbidClick: true,
          loadingType: "spinner",
        });
        register(qs.stringify(this.registerForm)).then((res) => {
          Toast.clear();
          if (res.data.code == 0) {
            Toast("注册成功,请登录!");
            this.optClick();
          } else {
            sessionStorage.removeItem("token");
            Toast(res.data.msg);
          }
        });
      }
    },
    isPhone(mobile) {
      var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (myreg.test(mobile)) {
        return false;
      } else {
        return true;
      }
    },
    tohome() {
      this.$router.replace("home");
    },
    whichPlatform() {
      if (window.screen.width < 800) {
        this.isApp = true;
      } else {
        this.isApp = false;
      }
    },
  },
  created() {
    this.whichPlatform();
  },
  mounted() {
    this.getNotice();
  },
};
</script>
<style scoped>
iframe {
  position: absolute;
  display: block;
  border: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.notice {
  margin: 0 auto;
  font-size: 14px;
}
.page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.module {
  border-radius: 10px;
  width: 800px;
  height: 100%;
  overflow-y: scroll;
}
.module::-webkit-scrollbar {
  display: none;
}
.module_app {
  width: 90%;
  height: 95%;
}
.module_data {
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
  background: #fff;
}
.module_banner {
  position: relative;
  height: 100px;
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  overflow: hidden;
  color: #fff;
  background-color: #148be4;
}
.module_top {
  display: inline-block;
  width: 94px;
  height: 94px;
  background: #fff;
  opacity: 0.1;
  border-radius: 50%;
  position: absolute;
  right: -16px;
  top: -16px;
}
.module_bottom {
  display: inline-block;
  width: 74px;
  height: 74px;
  background: #fff;
  opacity: 0.1;
  border-radius: 0 74px 0 0;
  position: absolute;
  left: 0;
  top: 43px;
}
.module_register {
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  background: #fff;
}
</style>
